import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`List the playlists on a channel`}</h2>
    <p>{`Returns data on a video playlists created on a specific channel.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/channels/{channel_id}/playlists.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pagesize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 50 by default, max. 50)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`filter[include_empty]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the value is `}<inlineCode parentName="td">{`true`}</inlineCode>{` then empty playlists will be returned (`}<inlineCode parentName="td">{`false`}</inlineCode>{` by default)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success, a set of key-value pairs is returned under a `}<inlineCode parentName="p">{`playlists`}</inlineCode>{` element.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the playlist.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the playlist`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`behavior`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values: `}<inlineCode parentName="td">{`static`}</inlineCode>{`, `}<inlineCode parentName="td">{`dynamic`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`total_duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sum of the duration of the videos added to the playlist in seconds.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thumbnail`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A set of key-value pairs containing URLs of the thumbnail images. Attributes: `}<inlineCode parentName="td">{`default`}</inlineCode>{` and various image sizes (e.g. `}<inlineCode parentName="td">{`image_112x63`}</inlineCode>{`, … , `}<inlineCode parentName="td">{`image_320x180`}</inlineCode>{`, `}<inlineCode parentName="td">{`image_640x360`}</inlineCode>{`, `}<inlineCode parentName="td">{`image_1920x1080`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`created_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of creation returned as a Unix timestamp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updated_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of last update returned as a Unix timestamp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the channel`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "playlists": [
        {
            "id": "111",
            "title": "Title of a playlist",
            "behavior": "static",
            "total_duration": "176.256",
            "thumbnail": {
                "default": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_192x108": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_112x63": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_128x72": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_256x144": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_320x180": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_640x360": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
                "image_1920x1080": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg"
            },
            "created_at": 1484127917,
            "updated_at": 1486569501,
            "channel_id": 11223344
        },
        ...
    ]
}
`}</code></pre>
    <p>{`The paging information can be found under the `}<inlineCode parentName="p">{`paging`}</inlineCode>{` key. Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"paging": {
    "previous": "https://api.video.ibm.com/channels/CHANNEL_ID/playlists.json?pagesize=PAGE_SIZE&page=PREVIOUS_PAGE",
    "next": "https://api.video.ibm.com/channels/CHANNEL_ID/playlists.json?pagesize=PAGE_SIZE&page=NEXT_PAGE",
    "page_count": 8,
    "item_count": 38
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel does not exist`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Get playlist details`}</h2>
    <p>{`Returns data of a specific video playlist.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/playlists/{playlist_id}.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success, a set of key-value pairs is returned under a `}<inlineCode parentName="p">{`playlists`}</inlineCode>{` element.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique ID of the playlist.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the playlist`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`behavior`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values: `}<inlineCode parentName="td">{`static`}</inlineCode>{`, `}<inlineCode parentName="td">{`dynamic`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`total_duration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sum of the duration of the videos added to the playlist in seconds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thumbnail`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A set of key-value pairs containing URLs of the thumbnail images. Attributes: `}<inlineCode parentName="td">{`default`}</inlineCode>{` and various image sizes (e.g. `}<inlineCode parentName="td">{`image_112x63`}</inlineCode>{`, … , `}<inlineCode parentName="td">{`image_320x180`}</inlineCode>{`, `}<inlineCode parentName="td">{`image_640x360`}</inlineCode>{`, `}<inlineCode parentName="td">{`image_1920x1080`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`created_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of creation returned as a Unix timestamp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updated_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of last update returned as a Unix timestamp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID of the channel`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
"playlist": {
        "id": "111",
        "title": "Title of a playlist",
        "behavior": "static",
        "total_duration": "176.256",
        "thumbnail": {
            "default": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_192x108": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_112x63": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_128x72": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_256x144": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_320x180": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_640x360": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg",
            "image_1920x1080": "https://ustvstaticcdn1-a.akamaihd.net/111.jpg"
        },
        "created_at": 1484127917,
        "updated_at": 1486569501,
        "channel_id": 11223344
    }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 ForbiddenForbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel or playlist does not exist`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      